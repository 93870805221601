
import { Vue, Options } from 'vue-class-component';
import { NSpace, NButton, NIcon, NDrawer, NDrawerContent } from 'naive-ui';
import IconButton from './IconButton.vue';
import {
  AddSharp as AddIcon,
  CloseSharp as CloseIcon,
  AttachMoneySharp as ExpenseIcon,
  MoreHorizSharp as OtherIcon
} from '@vicons/material';
import { FundOutlined as InvestmentIcon } from '@vicons/antd';
import AddingExpense from './AddingExpense.vue';
import AddingInvestment from './AddingInvestment.vue';
import AddingOther from './AddingOther.vue';
import { globalState } from '@/App.vue';
import { getTransactions } from '@/lib/fetcher';
import { getBalance } from '@/lib/connector';
import { Transaction } from '@/models/transaction';

@Options({
  components: {
    NButton,
    NIcon,
    NSpace,
    NDrawer,
    NDrawerContent,
    AddIcon,
    CloseIcon,
    ExpenseIcon,
    InvestmentIcon,
    OtherIcon,
    IconButton,
    AddingExpense,
    AddingInvestment,
    AddingOther
  }
})
export default class TransactionAdder extends Vue {
  isExpanded: boolean = false;
  addingType: 'expense' | 'investment' | 'other' | 'none' = 'none';

  dialogTitle() {
    switch (this.addingType) {
      case 'expense':
        return '新的支出';
      case 'investment':
        return '新的投资';
      case 'other':
        return '其他';
      default:
        return '';
    }
  }

  async onSubmitting() {
    this.hideAdder();
  }

  async onSubmitted(transaction: Transaction) {
    globalState.transactions.add(transaction);
    globalState.balance.addTransaction(transaction);
    getTransactions().then(list => {
      globalState.transactions = list;
    });
    getBalance().then(balance => {
      globalState.balance = balance;
    });
  }

  hideAdder() {
    this.addingType = 'none';
  }
}
