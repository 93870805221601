
import { Vue, Options } from 'vue-class-component';
import TransactionItem from './TransactionItem.vue';
import TransactionAdder from './TransactionAdder.vue';
import TransactionStats from './TransactionStats.vue';
import { TransactionList } from '@/models/transaction';
import { globalState } from '@/App.vue';
import { getTransactions } from '@/lib/fetcher';
import { NSkeleton } from 'naive-ui';
import { getBalance } from '@/lib/connector';
import { Timestamp } from '@/models/common';
import { currentMonthStart } from '@/lib/common';

@Options({
  components: {
    TransactionItem,
    TransactionAdder,
    TransactionStats,
    NSkeleton
  },
  props: {
    transactions: TransactionList,
    loading: Boolean
  }
})
export default class Transactions extends Vue {
  transactions!: TransactionList;
  loading: boolean = false;
  refreshing: boolean = false;

  async onRefresh() {
    this.refreshing = true;
    globalState.transactions = await getTransactions(
      currentMonthStart().valueOf()
    );
    globalState.balance = await getBalance();
    this.refreshing = false;
    (this.$refs.transactionsList as any).scrollTop = 0;
  }

  async onDelete(timestamp: Timestamp) {
    globalState.transactions.remove(timestamp);
    getTransactions(timestamp).then(list => {
      globalState.transactions = list;
    });
    getBalance().then(b => {
      globalState.balance = b;
    });
  }
}
