
import { Options, Vue } from 'vue-class-component';
import { Transaction, TransactionType } from '@/models/transaction';
import { globalState } from '@/App.vue';
import { deleteTransaction } from '@/lib/connector';
import {
  NIcon,
  NDrawer,
  NDrawerContent,
  useDialog
} from 'naive-ui';
import {
  ArrowDownwardRound as ArrowIcon,
  NoteAltOutlined as NoteIcon
} from '@vicons/material';

@Options({
  props: {
    transaction: Transaction
  },
  components: {
    NIcon,
    NDrawer,
    NDrawerContent,
    ArrowIcon,
    NoteIcon
  }
})
export default class TransactionItem extends Vue {
  transaction!: Transaction;
  showNote = false;
  dialog = useDialog();

  get avatar(): String {
    return this.transaction.creator === '傻爸' ? 'near.jpg' : 'pang.jpg';
  }

  get title(): String {
    const time = this.transaction.moment.format('YYYY-MM-DD HH:mm');
    return `${this.transactionType} @ ${time}`;
  }

  get date(): String {
    return `${this.transaction.month}.${this.transaction.date}`;
  }

  get transactionType(): String {
    return this.transaction.transactionTypeName();
  }

  get isInitialization(): boolean {
    return this.transaction.transactionType === TransactionType.Initialize;
  }

  get direction(): String[] {
    return this.transaction.direction(globalState.accounts);
  }

  get amount(): String {
    return this.transaction.amount().toStr();
  }

  onHold(event: any) {
    this.dialog.warning({
      title: '',
      content: '你确定要删除这条记录吗?',
      positiveText: '删除',
      negativeText: '取消',
      onPositiveClick: () => {
        deleteTransaction(this.transaction.timestamp);
        this.$emit('delete', this.transaction.timestamp);
      },
      onNegativeClick: () => {
      }
    });
  }
}
