
import { Vue, Options } from 'vue-class-component';
import Button from './Button.vue';
import { NIcon } from 'naive-ui';

@Options({
  components: {
    NIcon,
    Button
  },
  props: {
    shape: String,
    selected: Boolean,
    size: String
  }
})
export default class IconButton extends Vue {
  shape: String = 'circle';
  selected: boolean = false;
  size: String = '';
}
