
import { globalState } from '@/App.vue';
import { getUser, setUser } from '@/lib/common';
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class UserSelector extends Vue {
  onSelectUser(user: string) {
    setUser(user);
    globalState.user = getUser();
    this.$emit('updated');
  }
}
