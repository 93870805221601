
import { Options, Vue } from 'vue-class-component';
import { NInput } from 'naive-ui';

@Options({
  components: {
    NInput
  },
  props: {
    onUpdate: Function
  }
})
export default class MoneyInput extends Vue {
  value = '';
}
