
import { Vue, Options } from 'vue-class-component';
import { ArrowDownwardRound as ArrowIcon } from '@vicons/material';
import Button from './Button.vue';
import MoneyInput from './MoneyInput.vue';
import NoteInput from './NoteInput.vue';
import AccountsSelector from './AccountsSelector.vue';
import { TransactionType } from '@/models/transaction';
import { Money } from '@/models/common';
import { NButton, NSpace, NIcon, useMessage } from 'naive-ui';
import { addTransaction } from '@/lib/connector';
import { AccountId } from '@/models/account';
import { getUser } from '@/lib/common';

@Options({
  components: {
    Button,
    MoneyInput,
    NoteInput,
    AccountsSelector,
    NButton,
    NSpace,
    NIcon,
    ArrowIcon
  }
})
export default class AddingExpense extends Vue {
  investmentTypeStr: keyof typeof TransactionType = 'Investment';
  value: Money | undefined = undefined;
  note: string = '';
  fromAccountId: AccountId | undefined = undefined;
  toAccountId: AccountId | undefined = undefined;
  message = useMessage();

  setInvestmentType(newType: keyof typeof TransactionType) {
    this.investmentTypeStr = newType;
  }

  get investmentType(): TransactionType {
    return TransactionType[this.investmentTypeStr];
  }

  setValue(value: string) {
    this.value = Money.fromStr(value);
  }

  setNote(note: string) {
    this.note = note;
  }

  setFromAccountId(accountId: AccountId) {
    this.fromAccountId = accountId;
  }

  setToAccountId(accountId: AccountId) {
    this.toAccountId = accountId;
  }

  get fromAccountTypes() {
    switch (this.investmentType) {
      case TransactionType.Investment:
        return ['Cash'];
      case TransactionType.Redemption:
        return ['Investment'];
      default:
        return [];
    }
  }

  get toAccountTypes() {
    switch (this.investmentType) {
      case TransactionType.Investment:
        return ['Investment'];
      case TransactionType.Redemption:
        return ['Cash'];
      case TransactionType.InvestmentIncome:
        return ['Investment', 'Cash'];
      default:
        return [];
    }
  }

  get accountOwner() {
    return getUser();
  }

  async submit() {
    if (this.value === undefined) {
      this.message.error('金额输入不正确');
      return;
    }
    if (this.investmentType === undefined) {
      this.message.error('请选择类型');
      return;
    }
    if (this.fromAccountId === undefined) {
      this.message.error('请选择转出账户');
      return;
    }
    if (this.toAccountId === undefined) {
      this.message.error('请选择转入账户');
      return;
    }
    this.$emit('submitting');
    const addedTransaction = await addTransaction(
      getUser(),
      this.note || '',
      this.investmentType!,
      this.value.toStr(),
      this.fromAccountId!,
      this.toAccountId!
    );
    this.$emit('submitted', addedTransaction);
  }

  cancel() {
    this.$emit('cancelled');
  }
}
