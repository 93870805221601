
import { Options, Vue } from 'vue-class-component';
import { NSelect } from 'naive-ui';
import { Account, AccountType } from '@/models/account';
import { globalState } from '@/App.vue';

@Options({
  components: {
    NSelect
  },
  props: {
    types: Array,
    owners: Array,
    onUpdate: Function,
    title: String
  }
})
export default class AccountsSelector extends Vue {
  types: (keyof typeof AccountType)[] = [];
  owners: string[] | undefined = [];
  accountId: number | null = null;
  eligibleAccounts: Account[] = [];
  onUpdate!: Function;

  beforeCreate() {
    const accountTypes = this.types.map(type => AccountType[type]);
    this.eligibleAccounts =
      this.owners === undefined
        ? globalState.accounts.withTypesAndOwner(accountTypes, [])
        : globalState.accounts.withTypesAndOwner(accountTypes, this.owners);
    this.accountId = this.eligibleAccounts[0].id;
    this.onUpdate(this.accountId);
  }

  get eligibleAccountAsOptions(): any[] {
    return this.eligibleAccounts.map(account => {
      return {
        label: account.fullName(),
        value: account.id
      };
    });
  }
}
