
import { Vue, Options } from 'vue-class-component';
import {
  ShoppingBagOutlined as ShoppingIcon,
  FastfoodOutlined as FoodIcon,
  CommuteOutlined as CommuteIcon,
  WarningAmberSharp as ExceptionalIcon
} from '@vicons/material';
import IconButton from './IconButton.vue';
import MoneyInput from './MoneyInput.vue';
import NoteInput from './NoteInput.vue';
import AccountsSelector from './AccountsSelector.vue';
import { TransactionType } from '@/models/transaction';
import { Money } from '@/models/common';
import { globalState } from '@/App.vue';
import {
  NButton,
  NSpace,
  NRadioGroup,
  NRadioButton,
  NMessageProvider,
  useMessage
} from 'naive-ui';
import { addTransaction } from '@/lib/connector';
import { AccountId } from '@/models/account';
import { getUser } from '@/lib/common';

@Options({
  components: {
    ShoppingIcon,
    IconButton,
    FoodIcon,
    CommuteIcon,
    ExceptionalIcon,
    MoneyInput,
    NoteInput,
    AccountsSelector,
    NButton,
    NSpace,
    NRadioGroup,
    NRadioButton,
    NMessageProvider
  }
})
export default class AddingExpense extends Vue {
  expenseTypeStr: keyof typeof TransactionType = 'Food';
  value: Money | undefined = undefined;
  note: string = '';
  selectedAccountId: AccountId | undefined = undefined;
  message = useMessage();

  setExpenseType(newType: keyof typeof TransactionType) {
    this.expenseTypeStr = newType;
  }

  get expenseType(): TransactionType {
    return TransactionType[this.expenseTypeStr];
  }

  setValue(value: string) {
    this.value = Money.fromStr(value);
  }

  setNote(note: string) {
    this.note = note;
  }

  setAccountId(accountId: AccountId) {
    this.selectedAccountId = accountId;
  }

  get accountOwner() {
    return getUser();
  }

  async submit() {
    if (this.value === undefined) {
      this.message.error('金额输入不正确');
      return;
    }
    if (this.expenseType === undefined) {
      this.message.error('请选择支出类型');
      return;
    }
    if (this.selectedAccountId === undefined) {
      this.message.error('请选择支出账户');
      return;
    }
    this.$emit('submitting');
    const addedTransaction = await addTransaction(
      getUser(),
      this.note || '',
      this.expenseType!,
      this.value.toStr(),
      this.selectedAccountId!,
      globalState.accounts.expenseAccountId()
    );
    this.$emit('submitted', addedTransaction);
  }

  cancel() {
    this.$emit('cancelled');
  }
}
