
import { Options, Vue } from 'vue-class-component';
import { NCard, NIcon, NDrawer, NDrawerContent } from 'naive-ui';
import { globalState } from '@/App.vue';
import {
  InfoOutlined as InfoIcon,
  RefreshSharp as RefreshIcon,
  ListAltSharp as ListIcon
} from '@vicons/material';
import { currentTime } from '@/lib/common';

@Options({
  components: {
    NCard,
    NIcon,
    NDrawer,
    NDrawerContent,
    InfoIcon,
    RefreshIcon,
    ListIcon
  }
})
export default class TransactionStats extends Vue {
  showExpenseStats: boolean = false;
  showBalance: boolean = false;

  get month() {
    return globalState.transactions.month();
  }

  get isBirthday() {
    const date = currentTime();
    return date.date() === 6 && date.month() + 1 === 7;
  }

  get expenseClass() {
    return globalState.transactions.totalExpense().cents > 10000 * 100
      ? 'digits warning'
      : 'digits';
  }

  get budgetRatio() {
    return globalState.transactions.totalExpense().cents / 10000 / 100;
  }

  get totalIncome() {
    return globalState.transactions.totalIncome().toStr();
  }

  get totalExpense() {
    return globalState.transactions.totalExpense().toStr();
  }

  get totalExpenseCount() {
    return globalState.transactions.totalExpenseCount();
  }

  get expenseGroupList() {
    return globalState.transactions.expenseGroups();
  }

  get balancesList() {
    return globalState.balance.asBalanceStats(globalState.accounts);
  }

  get totalAsset() {
    return globalState.balance.totalAsset(globalState.accounts);
  }

  refreshTransactions() {
    this.$emit('refresh');
  }
}
